'use strict';

var CassidyAIHelper = require('../../../../app_storefront_core/cartridge/js/helpers/CassidyAIHelper');
// var slickHelpers = require('../../../../app_storefront_core/cartridge/js/slickSliderHelpers');

var countryoutfitterApp = {
	init: function () {
		//    slickHelpers.init();
	}
};

//initialize app
$(document).ready(function () {
	countryoutfitterApp.init();
	CassidyAIHelper.init();
});